import './styles.css'

import { getSegmentAnalytics } from '@motion/web-common/analytics'
import { isMobileExperience } from '@motion/web-common/env'
import { markExposure, useExperiment } from '@motion/web-common/flags'
import {
  getLatestNavigationTiming,
  stats,
} from '@motion/web-common/performance'
import { Sentry } from '@motion/web-common/sentry'

import { getApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, type User } from 'firebase/auth'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { CheckoutPaymentPage } from './_components/checkout-payment-page'
import { ChoosePlanPage, type PlanType } from './_components/choose-plan-page'

import { setAnalyticsUserId } from '../analytics'
import { SignUpPage } from '../areas/auth/pages/sign-up-page'
import { isEmailValid } from '../utils'

const isMobile = isMobileExperience()

type CheckoutPageProps = {
  setUserEmail: (email: string) => void
}

export const CheckoutPage = ({ setUserEmail }: CheckoutPageProps) => {
  const [user, setUser] = useState<User | null | undefined>(undefined)
  // track this in state we don't want to send mobile users who already
  // auth'd into the new flow. they have to explicitly enter email first
  const [userEmailOverride, setUserEmailOverride] = useState('')
  const [trialOverrideMode, setTrialOverrideMode] = useState(false)

  const b2bOnMobileFlag = useExperiment('b2b-on-mobile')
  const shouldShowB2bOnMobile = b2bOnMobileFlag.value === 'on'

  const [planType, setPlanType] = useState<
    Omit<PlanType, 'Enterprise'> | undefined
  >(undefined)

  const auth = getAuth(getApp())

  const navigate = useNavigate()

  useEffect(function handleAnalytics() {
    getSegmentAnalytics()?.track('add_to_cart', {
      category: 'checkout',
      url: window.location.href,
    })
  }, [])

  const handleMobileSignIn = useCallback(
    (email: string, mode: 'email-code' | 'firebase' | 'manual') => {
      if (mode === 'manual') {
        stats.mark('checkout.login')
      }

      getSegmentAnalytics()?.track('checkout_sign_in_email', {
        url: window.location.href,
      })
      setUserEmail(email)
      setUserEmailOverride(email)
      getSegmentAnalytics()?.identify(email, {
        email: email,
      })
      setAnalyticsUserId(email)
      Sentry.setUser({ id: email })
    },
    [setUserEmail]
  )

  useEffect(
    function handleExposure() {
      if (isMobile) {
        markExposure('b2b-on-mobile')
      }
    },
    [b2bOnMobileFlag]
  )

  useEffect(
    function handleAuth() {
      const newTrialOverride = new URLSearchParams(window.location.search).get(
        'newTrial'
      )
      if (newTrialOverride) {
        setTrialOverrideMode(true)
      }
      onAuthStateChanged(auth, (user) => {
        if (user) {
          if (!newTrialOverride && !isMobile) {
            navigate('/web')
            return
          }
          setUser(user)
        } else {
          const entry = getLatestNavigationTiming()

          setUser(null)
          const emailCode = new URLSearchParams(window.location.search).get('e')
          if (isMobile && emailCode) {
            const email = window.atob(emailCode)
            if (isEmailValid(email)) {
              return handleMobileSignIn(email, 'email-code')
            }
          }

          stats.distribution(
            'checkout.login.visible',
            performance.now() - entry.requestStart
          )
        }
      })
    },
    [auth, handleMobileSignIn, navigate]
  )

  if (user === undefined) {
    return null
  }

  const userEmail = user?.email || userEmailOverride

  return !userEmail ? (
    <div className='fill' data-theme='light'>
      <SignUpPage
        title='👋 Try Motion for free!'
        handleMobileSignIn={(email) => handleMobileSignIn(email, 'manual')}
        disableEmailSignin={trialOverrideMode}
      />
    </div>
  ) : shouldShowB2bOnMobile && !planType ? (
    <ChoosePlanPage setPlanType={setPlanType} email={userEmail} />
  ) : (
    <CheckoutPaymentPage
      userEmail={userEmail}
      signOutEmail={() => {
        auth.signOut()
        setUser(null)
        setUserEmailOverride('')
      }}
      isTrial
      isTeam={planType === 'Team'}
      onBack={shouldShowB2bOnMobile ? () => setPlanType(undefined) : undefined}
    />
  )
}
