import {
  EmailPasswordPreSignup,
  LoginButton,
  LoginScreen,
} from '@motion/ui/login'
import { isEmailValid } from '@motion/ui-logic'
import { isMobileExperience } from '@motion/web-common/env'
import { markExposure, useHasTreatment } from '@motion/web-common/flags'

import { useOnboardingIframeUrl } from 'experiments'
import { getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { useEffect, useState } from 'react'

import { MobileSignUpPage } from './mobile-sign-up-page'

import { logEvent } from '../../../analytics'
import { signInApple, signInGoogle, signInMicrosoft } from '../../../utils'
import { EmailPasswordSignup } from '../components/email-password-signup'
import { TermsPrivacyConsent } from '../components/terms-privacy-consent'

interface SignUpPageProps {
  title: string
  handleMobileSignIn: (email: string) => void
  disableEmailSignin: boolean
}

const isMobile = isMobileExperience()

export const SignUpPage = ({
  title,
  handleMobileSignIn,
  disableEmailSignin,
}: SignUpPageProps) => {
  const auth = getAuth(getApp())
  const [email, setEmail] = useState<string>('')
  const [isAddingNamePassword, setIsAddingNamePassword] =
    useState<boolean>(false)

  const iframeUrl = useOnboardingIframeUrl('signup')

  const isEmailSignupEnabled = useHasTreatment(
    'email-signin-checkout',
    'treatment'
  )

  const signInRedirectEnabled = useHasTreatment('signin-redirect')

  useEffect(() => {
    markExposure('email-signin-checkout')
    markExposure('onboarding-iframes')
    markExposure('signin-redirect')
  }, [])

  if (isMobile) {
    return (
      <MobileSignUpPage title={title} handleSignInEmail={handleMobileSignIn} />
    )
  }

  if (isAddingNamePassword) {
    return (
      <EmailPasswordSignup
        email={email}
        onBack={() => {
          setIsAddingNamePassword(false)
        }}
      />
    )
  }

  const onEmailPasswordPreSignup = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    logEvent('DASHBOARD_MOTION_PRESIGNUP_CLICK', { email })
    setIsAddingNamePassword(true)
  }

  return (
    <LoginScreen
      iframeUrl={iframeUrl}
      title={title}
      subtitle='Log in with your Google, Microsoft, or Apple account.'
    >
      <div className='flex flex-col gap-2.5 w-full'>
        <LoginButton
          onClick={() => signInGoogle(auth, signInRedirectEnabled)}
          prefix='Sign up with'
          provider='GOOGLE'
        />
        <LoginButton
          onClick={() => signInMicrosoft(auth, signInRedirectEnabled)}
          prefix='Sign up with'
          provider='MICROSOFT'
        />
        <LoginButton
          onClick={() => signInApple(auth, signInRedirectEnabled)}
          prefix='Sign up with'
          provider='APPLE'
        />
        {isEmailSignupEnabled && !disableEmailSignin && (
          <EmailPasswordPreSignup
            email={email}
            disabled={!isEmailValid(email)}
            setEmail={setEmail}
            onSubmit={onEmailPasswordPreSignup}
          />
        )}
        <TermsPrivacyConsent />
      </div>
    </LoginScreen>
  )
}
