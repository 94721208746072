import { useAuth } from '@motion/web-common/auth'

import { LoadingPage } from '~/areas/global'
import { type ReactNode } from 'react'

import { LoginPage } from '../pages/login-page'

type EnsureAuthenticatedProps = {
  children: ReactNode
}

export function EnsureAuthenticated(props: EnsureAuthenticatedProps) {
  const { auth } = useAuth()

  if (auth.state === 'authenticating' || auth.state === 'signing-out') {
    // render spinner
    return <LoadingPage id='ensure-authenticated' />
  }

  if (
    auth.state === 'unauthenticated' ||
    // TODO: Handle error state better, like in the motion-extension version of EnsureAuthenticated
    auth.state === 'error' ||
    !auth.user.email
  ) {
    return (
      <div className='fill' data-theme='light'>
        <LoginPage title='👋 Subscribe to Motion' showIframe />
      </div>
    )
  }

  return props.children
}
