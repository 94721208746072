import { useTheme } from '@motion/ui/providers'

import { Elements } from '@stripe/react-stripe-js'
import { type Appearance, type Stripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'

interface ElementsWrapperProps {
  stripe: Stripe | null
  clientSecret: string
  children: React.ReactChild
}

export const ElementsWrapper = ({
  children,
  clientSecret,
  stripe,
}: ElementsWrapperProps) => {
  const [appearance, setAppearance] = useState<Appearance>({})

  const { resolve } = useTheme()

  useEffect(() => {
    setAppearance({
      theme: 'none',
      variables: {
        colorPrimary: '#0570de',
        colorBackground: resolve('semantic-neutral-bg-default'),
        colorText: resolve('semantic-neutral-text-default'),
        colorTextPlaceholder: resolve('semantic-neutral-text-subtle'),
        colorDanger: '#df1b41',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: '2px',
        spacingGridRow: '12px',
        spacingGridColumn: '12px',
        borderRadius: '4px',
      },
    })
  }, [resolve])

  return (
    <Elements
      key={clientSecret}
      options={{ clientSecret, appearance }}
      stripe={stripe}
    >
      {children}
    </Elements>
  )
}
