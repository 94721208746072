import { getSegmentAnalytics } from '@motion/web-common/analytics'
import { useAuth } from '@motion/web-common/auth'
import { Sentry } from '@motion/web-common/sentry'

import { useEffect } from 'react'

import { setAnalyticsUserId } from '../../analytics'

export const InitializeAnalytics = () => {
  const { auth } = useAuth()

  useEffect(() => {
    if (auth.state !== 'authenticated') {
      return
    }
    const { user } = auth

    if (user.email) {
      getSegmentAnalytics()?.identify(user.email, {
        name: user.displayName,
        email: user.email,
      })
      setAnalyticsUserId(user.email)
    }

    Sentry.setUser({ id: user.email ?? user.uid })
  }, [auth])
  return null
}
