import {
  getAnonymousId,
  initializeAmplitude as initializeAmplitudeInternal,
} from '@motion/web-common/analytics'
import { tagToSemVer } from '@motion/web-common/utils'

export {
  logEvent,
  setAnalyticsUserId,
  setAnalyticsTeamId,
  setAnalyticsUserProperties,
} from '@motion/web-common/analytics'

type Options = {
  version: string
}
export function initializeAmplitude(options: Options) {
  initializeAmplitudeInternal({
    apiKey: __IS_PROD__
      ? '6fe2bcadaeac4de59911c38cf1bc6f6a'
      : '1ede2f04e65de0e105d73724b9837c95',
    endpoint: 'https://amplitude.inmotion.app/2/httpapi',
    version: tagToSemVer(options.version),
    deviceId: getAnonymousId(),
  })
}
