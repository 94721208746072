import { Button } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'

import logo from '~/images/logo_1024.png'
import { getApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { isEmailValid, sendResetPasswordEmail } from '../../../utils'
import { EmailPasswordSignup } from '../components/email-password-signup'

type EmailState = 'initial' | 'sending' | 'sent'

export const ForgotPasswordPage = () => {
  const navigate = useNavigate()
  const auth = getAuth(getApp())

  const [email, setEmail] = useState('')
  const [emailState, setEmailState] = useState<EmailState>('initial')
  const [isSigningUp, setIsSigningUp] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/web')
      }
    })
  }, [auth, navigate])

  const getButtonText = (state: EmailState) => {
    switch (state) {
      case 'initial':
        return 'Reset password'
      case 'sending':
        return 'Sending email...'
      case 'sent':
        return 'Email sent'
    }
  }

  const handleReset = useCallback(async () => {
    setEmailState('sending')
    const result = await sendResetPasswordEmail(auth, email)
    if (result.outcome) {
      setEmailState('sent')
      return
    }

    setEmailState('initial')
    const code = result?.error?.code
    if (!code) {
      setError('Something went wrong. Please contact support@usemotion.com')
      return
    }
    // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#sendpasswordresetemail
    switch (code) {
      case 'auth/invalid-email':
        setError('Please enter a valid email address.')
        return
      case 'auth/user-not-found':
        setIsSigningUp(true)
        return
      default:
        setError('Something went wrong. Please contact support@usemotion.com')
    }
  }, [auth, email, setEmailState, setError])

  if (emailState === 'sent') {
    return (
      <div className='flex w-full h-full px-6 py-8 lg:py-[72px] justify-center items-center'>
        <div className='flex flex-col w-full max-w-[730px] gap-y-8 lg:gap-y-14'>
          <img
            src={logo}
            alt='Motion logo'
            className='rounded w-10 h-10 lg:w-12 lg:h-12'
          />
          <div className='flex flex-col w-full gap-y-9'>
            <div className='flex flex-col w-full gap-y-4'>
              <p className='font-semibold text-3xl text-semantic-neutral-text-default'>
                Password Recovery
              </p>
              <div className='flex flex-col gap-4 lg:gap-y-8 text-lg text-semantic-neutral-text-subtle text-[15px]'>
                <p>
                  If there is an account with the email{' '}
                  <span className='font-semibold'>{email}</span> you will
                  receive an email from us shortly.
                </p>
                <div>
                  <h2 className='font-semibold text-semantic-neutral-text-default'>
                    Didn't receive an email?
                  </h2>
                  <p>
                    If you did not receive an email, but are certain you have an
                    existing Motion account, please check your other email
                    inboxes to see which email you’ve been receiving emails or
                    invoices from Motion (harry@usemotion.app). Please make sure
                    to log in with the same email account you signed up with. If
                    you cannot login to your existing account, contact support
                    at{' '}
                    <a
                      href='https://help.usemotion.com'
                      target='_blank'
                      rel='noreferrer'
                      className='text-semantic-primary-text-default underline'
                    >
                      help.usemotion.com
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
            <Button
              variant='outlined'
              sentiment='neutral'
              onClick={() => navigate('/')}
            >
              Back to log in
            </Button>
          </div>
        </div>
      </div>
    )
  }

  if (isSigningUp && email) {
    return (
      <div
        className='flex h-full w-full items-center justify-center px-6 py-8'
        data-theme='light'
      >
        <EmailPasswordSignup
          email={email}
          onBack={() => {
            setIsSigningUp(false)
          }}
        />
      </div>
    )
  }

  return (
    <div
      className='flex w-full h-full px-6 py-8 lg:py-[72px] justify-center items-center'
      data-theme='light'
    >
      <div className='flex flex-col w-full max-w-[600px] gap-y-8 lg:gap-y-14'>
        <img
          src={logo}
          alt='Motion logo'
          className='rounded w-10 h-10 lg:w-12 lg:h-12'
        />
        <div className='flex flex-col w-full gap-y-9'>
          <div className='flex flex-col w-full gap-y-4'>
            <p className='font-semibold text-3xl text-semantic-neutral-text-default'>
              Reset Password
            </p>
            <p className='text-base text-semantic-neutral-text-subtle'>
              If you already signed up for Motion with email and password,
              we&apos;ll email you instructions how to reset your password. If
              you haven't set a password, we&apos;ll prompt you to set a
              password
            </p>
          </div>
          <div className='flex flex-col w-full gap-y-4'>
            <div className='flex flex-col w-full gap-y-2'>
              <p className='font-semibold text-semantic-neutral-text-subtle'>
                Email
              </p>
              <TextField
                placeholder='name@company.com'
                value={email}
                onChange={(value) => setEmail(value)}
              />
            </div>
            <div className='flex flex-col w-full gap-y-2'>
              {error && (
                <p className='text-semantic-error-text-default'>{error}</p>
              )}
              <Button
                loading={emailState === 'sending'}
                onClick={() => handleReset()}
                disabled={!isEmailValid(email)}
              >
                {getButtonText(emailState)}
              </Button>
              <Button
                variant='muted'
                sentiment='neutral'
                onClick={() => navigate('/')}
              >
                Back
              </Button>
              <div>
                <h2 className='semantic-neutral-text-default font-semibold text-[15px] pb-2'>
                  Forgot your email?
                </h2>
                <p className='text-semantic-neutral-text-subtle text-[15px]'>
                  If you did not receive an email, but are certain you have an
                  existing Motion account, please check your other email inboxes
                  to see which email you’ve been receiving emails or invoices
                  from Motion (harry@usemotion.app). Please make sure to log in
                  with the same email account you signed up with. If you cannot
                  login to your existing account, contact support at{' '}
                  <a
                    className='text-semantic-primary-text-default underline'
                    href='https://help.usemotion.com'
                    target='_blank'
                    rel='noreferrer'
                  >
                    help.usemotion.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
