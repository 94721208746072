import { LoadingSpinner } from '@motion/ui/base'
import { Sentry } from '@motion/web-common/sentry'

import { getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { useEffect, useState } from 'react'

import { NewOnboardingAddAccount } from './NewOnboardingAddAccount'
import { getCloudRunUrl } from './utils'

/* eslint react-refresh/only-export-components: ["warn"] */
export const GOOGLE_OAUTH_URL = getCloudRunUrl(
  'email_accounts/oauth/google/get_redirect_url'
)

export const MICROSOFT_OAUTH_URL = getCloudRunUrl(
  'email_accounts/oauth/microsoft/get_redirect_url'
)

export interface AddAccountProps {
  limitedPermissions?: boolean
  accountType?: 'google' | 'microsoft'
}

function buildUrl(baseUri: string, opts: AddAccountProps) {
  const searchParams = new URLSearchParams()
  if (opts.limitedPermissions) {
    searchParams.set('limitedPermissions', 'true')
  }

  const params = searchParams.toString()
  if (!params) {
    return baseUri
  }
  return `${baseUri}?${params}`
}

export const AddAccount = ({ limitedPermissions }: AddAccountProps) => {
  const auth = getAuth(getApp())

  const [isLoading, setIsLoading] = useState(false)
  const [hasUser, setHasUser] = useState(false)

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      setHasUser(user != null)
    })
  }, [auth])

  const gotoRedirectUrl = async (baseUrl: string) => {
    setIsLoading(true)

    const url = buildUrl(baseUrl, { limitedPermissions })
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
        },
        method: 'POST',
      })

      if (!response.ok) {
        // TODO
        setIsLoading(false)
        return
      }

      const json = await response.json()
      window.location.href = json.url
    } catch (e) {
      setIsLoading(false)
      Sentry.captureException(e, {
        tags: {
          position: 'add-account',
        },
        extra: {
          url,
        },
      })
    }
  }

  if (isLoading || !hasUser) {
    return (
      <div className='fill ms-oauth-container'>
        <LoadingSpinner size={22} className='availabilities-spinner' />
      </div>
    )
  }

  return <NewOnboardingAddAccount doRedirect={gotoRedirectUrl} />
}
