import { Button, Toast } from '@motion/ui/base'
import { isWebview } from '@motion/utils/userAgent'

import logo from '~/images/logo_1024.png'
import React from 'react'

export function PreOnboardingDone() {
  const [bannerText, setBannerText] = React.useState<string | null>(null)
  const [toastTime, setToastTime] = React.useState<number | null>(null)
  const inWebview = isWebview(window.navigator.userAgent)

  const emailCode = new URLSearchParams(window.location.search).get('emailCode')
  const link = `/web?${emailCode ? `emailCode=${emailCode}` : ``}`

  return (
    <div className='h-full w-full items-center justify-center bg-neutral-100'>
      <div className='flex flex-col h-full mx-6 gap-6 items-center justify-center'>
        <img src={logo} className='w-12 h-12' alt='Motion logo' />

        {/* Copy */}
        <div className='flex flex-col gap-4'>
          <h1 className='text-light-1200 text-center text-2xl font-bold'>
            Motion is best experienced using our web app{' '}
          </h1>
          <p className='text-light-1100 text-center text-base font-normal'>
            {/* TODO there is no email code yet */}
            Go to our web app for the best experience. We've sent you an email
            with a link to continue on our web app.
          </p>
        </div>

        {/* Buttons */}
        <div className='flex flex-col gap-2 w-full'>
          <Button
            onClick={() => {
              setBannerText(
                'Check the email we sent you to onboard on our web app! You can close this tab now.'
              )
              setToastTime(Date.now())
            }}
          >
            Continue on the web app
          </Button>
          <Button
            sentiment='neutral'
            variant='muted'
            onClick={() => {
              if (!inWebview) {
                window.location.href = link
              } else {
                // Google oAuth doesn't work from inside of a webview. We need to direct the user to their native browser
                navigator.clipboard.writeText(`${__FRONTEND_HOST__}${link}`)
                setBannerText(
                  "Continue onboarding in Chrome or Safari. We've copied a link to paste into your browser."
                )
                setToastTime(Date.now())
              }
            }}
          >
            Continue on mobile anyways
          </Button>
        </div>
      </div>

      {toastTime !== null && bannerText !== null && (
        <Toast
          visible
          duration={10_000}
          title={bannerText}
          onClose={() => {
            setToastTime(null)
            setBannerText(null)
          }}
        />
      )}
    </div>
  )
}
