import React from 'react'

import { FullScreenLoading } from './FullScreenLoading'

const WebLazyComponent = React.lazy(() =>
  import('@motion/extension').then((mod) => ({ default: mod.EntryPoint }))
)

export function WebLazy() {
  return (
    <React.Suspense fallback={<FullScreenLoading />}>
      <WebLazyComponent />
    </React.Suspense>
  )
}
