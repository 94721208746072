import {
  INDIVIDUAL_ANNUAL_PRICE,
  INDIVIDUAL_MONTHLY_PRICE,
} from '@motion/ui-logic/billing'

import { getCloudRunUrl } from '../utils'

export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: 'black',
      iconColor: 'black',
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

export const intervalMap = {
  motion_monthly: 'month',
  motion_annual: 'year',
}

export type ValueMap = {
  motion_monthly: number
  motion_annual: number
}

export const valueMap: ValueMap = {
  motion_monthly: INDIVIDUAL_MONTHLY_PRICE,
  motion_annual: INDIVIDUAL_ANNUAL_PRICE,
}

export const trialLength = 7

export const CREATE_PAYMENT_INTENT_UNAUTHORIZED_URL = getCloudRunUrl(
  'users/deprecated/paymentIntent/unauthorized'
)
