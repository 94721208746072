import { parseDate } from '@motion/utils/dates'

export function formatTimeRange(
  startTime: string,
  endTime: string,
  timezone: string
) {
  return [
    parseDate(startTime).toFormat('h:mma').toLocaleLowerCase(),
    '-',
    parseDate(endTime).toFormat('h:mma').toLocaleLowerCase(),
    timezone,
  ].join(' ')
}

export function formatFullDate(value: string) {
  return parseDate(value).toFormat('ccc LLL d, yyyy')
}

export function formatFullDateTimeRange(
  startTime: string,
  endTime: string,
  timezone: string
) {
  return `${formatTimeRange(startTime, endTime, timezone)} on ${formatFullDate(startTime)}`
}
