import { Button, Tag } from '@motion/ui/base'

type MobileBillingPlanCardProps = {
  title: string
  subtitle: string
  savingsDescription?: string
  priceElement: JSX.Element
  onClick: () => void
  buttonText?: string
}

export const MobileBillingPlanCard = ({
  title,
  subtitle,
  priceElement,
  buttonText = 'Continue',
  savingsDescription,
  onClick,
}: MobileBillingPlanCardProps) => {
  return (
    <div className='flex flex-col gap-4 bg-semantic-neutral-bg-default p-4 rounded-lg'>
      <div className='flex flex-col gap-2'>
        <div className='flex flex-row items-center justify-between'>
          <h1 className='text-semantic-neutral-text-default font-bold text-sm'>
            {title}
          </h1>
          {savingsDescription && (
            <Tag size='small' color='green' variant='subtle'>
              <strong>{savingsDescription}</strong>
            </Tag>
          )}
        </div>
        <h2 className='text-semantic-neutral-text-subtle text-xs'>
          {subtitle}
        </h2>
      </div>
      <div className='flex flex-row items-center justify-between'>
        {priceElement}
        <Button size='small' onClick={onClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  )
}
