import './index.css'

import { setAnalyticsUserId } from '@motion/web-common/analytics'
import { type AuthStatus, useAuth } from '@motion/web-common/auth'
import { bus } from '@motion/web-common/event-bus'
import { Sentry } from '@motion/web-common/sentry'

import { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'

import { AccountPage } from './Account'
import { AddAccount } from './add-account'
import { AddPaymentLocalized } from './add-payment'
import { OauthInstructions } from './areas/auth/oauth/oauth-instructions'
import { OauthResponse } from './areas/auth/oauth/oauth-response'
import { OauthVerification } from './areas/auth/oauth/oauth-verification'
import { ChangePasswordPage } from './areas/auth/pages/change-password-page'
import { ForgotPasswordPage } from './areas/auth/pages/forgot-password-page'
import { BookingCancelPage } from './booking/booking-cancel-page'
import { BookingPage } from './booking/booking-page'
import { CheckoutPage } from './checkout'
import { PREONBOARDING_DONE_ROUTE } from './constants'
import { EnterprisePage } from './enterprise-page'
import { AmplitudeExperimentProvider } from './experiments/provider'
import { MobileInstall } from './MobileInstall'
import { NewTabPage } from './NewTabPage'
import { PreOnboardingDone } from './PreOnboardingDone'
import { Redirect } from './Redirect'
import { SyncUserCookieAuth } from './sync-cookie-auth'
import { logSignInEvent } from './utils'
import { WebLazy } from './WebLazy'
import { ZoomOauth } from './ZoomOauth'

window.addEventListener('message', (ev) => {
  if (ev.data.eventType === 'sentry-breadcrumb') {
    Sentry.addBreadcrumb(ev.data.payload)
  } else if (ev.data.eventType === 'sentry-event') {
    Sentry.captureEvent(ev.data.payload)
  }
})

function emailOf(auth: AuthStatus): string | null {
  if (auth.state === 'authenticated') return auth.user.email
  return null
}

export function App() {
  const { auth } = useAuth()
  const [unauthedEmail, setUnauthedEmail] = useState<string | null>(null)
  const userEmail = emailOf(auth) ?? unauthedEmail

  useEffect(function checkRedirectAuth() {
    bus.on('auth:redirect-result', ({ result }) => {
      if (result.operationType === 'signIn' && result.providerId) {
        logSignInEvent(result.providerId)
      }
    })
  }, [])

  useEffect(() => {
    if (auth.state === 'authenticated') {
      Sentry.setUser({ id: auth.user.email ?? auth.user.uid })
      setAnalyticsUserId(auth.user.email ?? auth.user.uid)
    } else {
      Sentry.setUser(null)
    }
    // @ts-expect-error - user is only present with state === 'authenticated'
  }, [auth.state, auth.user?.email, auth.user?.uid])

  return (
    <>
      <AmplitudeExperimentProvider userEmail={userEmail}>
        <SyncUserCookieAuth />
        <Routes>
          <Route path='/meet/:urlPrefix/:linkSlug' element={<BookingPage />} />
          <Route path='/meet/:linkSlug' element={<BookingPage />} />
          <Route path='/cancel/:bookingId' element={<BookingCancelPage />} />
          <Route path='/reschedule/:bookingId' element={<BookingPage />} />
          {['/checkout', '/startCheckout'].map((path) => (
            <Route
              key={path}
              path={path}
              element={<CheckoutPage setUserEmail={setUnauthedEmail} />}
            />
          ))}
          {[PREONBOARDING_DONE_ROUTE].map((path) => (
            <Route key={path} path={path} element={<PreOnboardingDone />} />
          ))}
          <Route path='/settings' element={<Redirect path='/web/settings' />} />
          <Route path='/account' element={<AccountPage />} />
          <Route path='/firebase-auth' element={<AccountPage />} />
          <Route path='/addPayment' element={<AddPaymentLocalized />} />
          <Route path='/zoom-oauth' element={<ZoomOauth />} />
          <Route path='/oauth-instructions' element={<OauthInstructions />} />
          <Route path='/mobile' element={<MobileInstall />} />
          <Route path='/' element={<NewTabPage />} />
          <Route path='/web/*' element={<WebLazy />} />
          <Route
            path='/forgot-password/change'
            element={<ChangePasswordPage />}
          />
          <Route
            path='/forgot-password/reset'
            element={<ForgotPasswordPage />}
          />

          <Route
            path='/add-account/:provider?'
            element={<AddAccount limitedPermissions={false} />}
          />
          <Route
            path='/add-limited-account'
            element={<AddAccount limitedPermissions />}
          />
          <Route
            path='/oauth/google'
            element={
              <OauthVerification
                providerName='Google'
                verificationUrl='email_accounts/oauth/google/verify'
              />
            }
          />
          <Route
            path='/oauth/microsoft'
            element={
              <OauthVerification
                providerName='Microsoft'
                verificationUrl='email_accounts/oauth/microsoft/verify'
              />
            }
          />
          <Route
            path='/oauth/google/response'
            element={<OauthResponse providerName='Google' />}
          />
          <Route
            path='/oauth/microsoft/response'
            element={<OauthResponse providerName='Microsoft' />}
          />
          <Route
            path='/enterprise/:urlPrefix/:linkSlug'
            element={<EnterprisePage />}
          />
        </Routes>
      </AmplitudeExperimentProvider>
    </>
  )
}
