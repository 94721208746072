import { Banner, Button } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { isPasswordValid } from '@motion/ui-logic'

import logo from '~/images/logo_1024.webp'
import { FirebaseError, getApp } from 'firebase/app'
import { confirmPasswordReset, getAuth } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const ChangePasswordPage = () => {
  const [searchParams] = useSearchParams()
  const oobCode = searchParams.get('oobCode')
  const auth = getAuth(getApp())

  const [isLoading, setIsLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [didSucceed, setDidSucceed] = useState(false)

  const changePassword = async () => {
    setIsLoading(true)
    if (!oobCode) {
      setError('This link has expired. Please reset your password again.')
      return
    }
    try {
      await confirmPasswordReset(auth, oobCode, password)
      setDidSucceed(true)
    } catch (error) {
      if (!(error instanceof FirebaseError)) {
        setError('Something went wrong. Please reset your password again.')
        return
      }

      // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#confirmpasswordreset
      switch (error.code) {
        case 'auth/expired-action-code': {
          setError('This link has expired. Please reset your password again.')
          break
        }
        case 'auth/invalid-action-code': {
          setError('This link is invalid. Please reset your password again.')
          break
        }
        case 'auth/weak-password': {
          setError(
            'Please choose a stronger password. We recommend at least 8 characters and one numeric character.'
          )
          break
        }
        case 'auth/user-disabled':
        case 'auth/user-not-found':
        default: {
          setError('This link is invalid. Please reset your password again.')
          break
        }
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(
    function validateOobCode() {
      if (!oobCode) {
        setError('This link is invalid. Please reset your password again.')
        return
      }
    },
    [oobCode]
  )

  return (
    <div className='w-full h-full bg-semantic-neutral-bg-default flex flex-col items-center px-8 py-9'>
      <div className='w-full h-full max-w-[600px] flex flex-col gap-y-9'>
        <img src={logo} className='h-9 w-9' alt='Motion Icon' />
        {didSucceed && (
          <Banner variant='onPage' sentiment='default'>
            Password updated! <a href='/'>Click here to log in.</a>
          </Banner>
        )}
        <p className='font-bold text-[32px] text-semantic-neutral-text-default'>
          Change Password
        </p>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            void changePassword()
          }}
        >
          <div className='w-full flex flex-col gap-y-4'>
            <TextField
              type='password'
              label='New password'
              value={password}
              placeholder='Enter at least 8 characters, with at least 1 number'
              onChange={(newPassword) => setPassword(newPassword)}
            />

            <div className='w-full flex flex-col gap-y-2'>
              {error && (
                <p className='text-lg text-semantic-error-text-default'>
                  {error}
                </p>
              )}
              <Button
                loading={isLoading}
                disabled={!isPasswordValid(password) || !oobCode || didSucceed}
                type='submit'
              >
                Reset password
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
