export const TermsPrivacyConsent = () => (
  <p className='text-xs text-semantic-neutral-text-subtle'>
    By signing up, you agree to our{' '}
    <a
      target='_blank'
      rel='noreferrer'
      href='https://www.usemotion.com/terms'
      className='underline'
    >
      Terms of Service
    </a>{' '}
    and{' '}
    <a
      target='_blank'
      rel='noreferrer'
      href='https://www.usemotion.com/privacy'
      className='underline'
    >
      Privacy Policy
    </a>
  </p>
)
