import { useSharedStateSendOnly } from '@motion/react-core/shared-state'
import { CookieAuthFlagKey } from '@motion/web-common/auth'
import { useHasTreatment } from '@motion/web-common/flags'

import { useEffect } from 'react'

export function SyncUserCookieAuth() {
  const setHasCookieAuth = useSharedStateSendOnly(CookieAuthFlagKey)
  const hasCookieAuth = useHasTreatment('cookie-auth-only')

  useEffect(
    function setHasCookieAuthOnly() {
      setHasCookieAuth(hasCookieAuth)
    },
    [hasCookieAuth, setHasCookieAuth]
  )

  return null
}
