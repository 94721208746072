import { MotionLogo } from '@motion/icons'

export const BookingLinkExpired = () => {
  return (
    <div className='availabilities-loading-container fill-page flex flex-col gap-8'>
      <MotionLogo className='w-full h-12' />
      <div className='availabilities-error-card'>
        {/* TODO update this copy once we can discriminate between "expired" and "invalid" links */}
        <div className='font-bold mb-2' style={{ color: '#151515' }}>
          Issue loading this booking page.
        </div>
        <div style={{ color: '#151515' }}>
          The page may have expired, or the URL may have been entered
          incorrectly.
        </div>
      </div>
    </div>
  )
}
