import { NoWifi } from '@motion/ui/screens'
import { useIsOnline } from '@motion/web-common/env'

import { type ReactNode } from 'react'

type EnsureOnlineProps = {
  children: ReactNode
}

export const EnsureOnline = (props: EnsureOnlineProps) => {
  const isOnline = useIsOnline()
  if (!isOnline) {
    return <NoWifi onClick={() => window.location.reload()} />
  }
  return props.children
}
