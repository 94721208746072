import logo from '~/images/logo_1024.png'
import { type User } from 'firebase/auth'
import { useEffect } from 'react'

import { getApiUrl } from './utils'

interface DesktopRedirectProps {
  user: User
}

export const DesktopRedirect = ({ user }: DesktopRedirectProps) => {
  const generateFirebaseToken = async () => {
    // TODO: Move this cloudrun function to the backend
    const bearer = `Bearer ${await user?.getIdToken()}`
    try {
      const response = await fetch(getApiUrl('generateFirebaseToken'), {
        method: 'post',
        headers: {
          Authorization: bearer,
          'Content-Type': 'application/json',
        },
      })
      const { token } = await response.json()
      if (token) {
        return token
      }
      throw new Error('no token returned')
    } catch (e) {
      void e
    }
  }

  const openDesktopApp = async (type: '_blank' | '_self') => {
    const customToken = await generateFirebaseToken()

    // Open desktop app with custom token to authenticate with
    window.open('motion-desktop://login?token=' + customToken, type)
  }

  useEffect(() => {
    // Open pop up window to authenticate with
    openDesktopApp('_self')

    // Below dependency is disabled because we want to run this effect only once
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='flex h-screen w-screen flex-col items-center justify-center gap-6 bg-white'>
      <img src={logo} alt='logo' className='h-12 w-12' />
      <div className='flex flex-col gap-1'>
        <p className='text-lg font-semibold'>
          Redirecting to your Motion App...
        </p>
        <button
          // Try to replace current window instead
          onClick={() => openDesktopApp('_self')}
          className='border-none text-blue-500 outline-none hover:text-blue-700'
        >
          If you weren’t redirected, click here.
        </button>
      </div>
      <button
        className='border-none text-[#9696A7] outline-none hover:text-black'
        onClick={() => window.open('https://app.usemotion.com/web/#calendar')}
      >
        Open Motion in browser
      </button>
    </div>
  )
}
