import { useExperiment } from '@motion/web-common/flags'

import { trialLength } from '../checkout/constants'

export function useTrialLength(): number {
  const flag = useExperiment('stripe-trial-length')
  return flag.payload?.days ?? trialLength
}

export function useOnboardingIframeUrl(page: string): string {
  const flag = useExperiment('onboarding-iframes')
  return flag.payload?.[page]?.url || ''
}
