import { Tag } from '@motion/ui/base'

import {
  type FocusEventHandler,
  type KeyboardEventHandler,
  useRef,
  useState,
} from 'react'

const VALID_EMAIL_RE =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

/* eslint react-refresh/only-export-components: ["warn"] */
export const isEmailValid = (email: string) => {
  return VALID_EMAIL_RE.test(email)
}

export type EmailSelectProps = {
  items: string[]
  onChange(items: string[]): void
}

const ADD_KEYS = [' ', 'Tab', ',', 'Enter', ';']

export const EmailSelect = (props: EmailSelectProps) => {
  const [text, setText] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  const onBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    const value = e.currentTarget.value.trim()
    if (!value) return

    props.onChange([...props.items, value])
    setText('')
  }

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (!ADD_KEYS.includes(e.key)) return

    const value = e.currentTarget.value.trim()
    if (!value) return

    props.onChange([...props.items, value])
    setText('')

    e.preventDefault()
    e.stopPropagation()
  }

  const onRemove = (email: string) =>
    props.onChange(props.items.filter((x) => x !== email))

  return (
    <div
      data-theme='light'
      className='flex flex-wrap content-start gap-1 p-2 rounded border cursor-text min-h-[121px]'
      onClick={() => inputRef.current?.focus()}
    >
      {props.items.map((x, idx) => {
        return <EmailTag key={idx} email={x} onRemove={() => onRemove(x)} />
      })}
      <input
        ref={inputRef}
        autoComplete='off'
        value={text}
        className='border-none outline-none h-4'
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onChange={(e) => setText(e.currentTarget.value.trim())}
        data-value={text}
        size={(text.length || 1) + 1}
      />
    </div>
  )
}

type EmailTagProps = {
  email: string
  onRemove(): void
}

const EmailTag = (props: EmailTagProps) => {
  const color = isEmailValid(props.email) ? 'grey' : 'red'
  const variant = isEmailValid(props.email) ? 'subtle' : 'default'

  return (
    <Tag onRemove={props.onRemove} color={color} size='small' variant={variant}>
      {props.email}
    </Tag>
  )
}
