import { Button } from '@motion/ui/base'

import { useState } from 'react'

const surveyOptions = [
  'Facebook or Instagram',
  'Friend or co-worker',
  'Article or newsletter',
  'Google',
  'Pinterest',
  'Twitter',
  'YouTube',
  'TikTok',
  'LinkedIn',
  'Banner Ad',
]

/* eslint react-refresh/only-export-components: ["warn"] */
export const shuffleArray = (arr: string[]): string[] => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[arr[i], arr[j]] = [arr[j], arr[i]]
  }

  return arr
}

const randomizedSurveyOptions = [...shuffleArray(surveyOptions), 'Other']

export const CheckoutSurvey = ({
  onComplete,
}: {
  onComplete: (surveyResult: string) => void
}) => {
  const [surveyResult, setSurveyResult] = useState<string | undefined>(
    undefined
  )
  const [surveySubmitting, setSurveySubmitting] = useState(false)

  return (
    <div className='checkout-survey-container'>
      <div className='checkout-survey-top'>
        Your trial has been started successfully!
      </div>
      <div className='checkout-survey-bottom gap-4'>
        <div style={{ color: '#1f2937', fontSize: 16, fontWeight: 'bold' }}>
          How did you hear about Motion?
        </div>
        <div className='px-1 flex flex-col gap-1'>
          {randomizedSurveyOptions.map((opt) => (
            <RadioButton
              key={opt}
              option={opt}
              onChange={(value) => setSurveyResult(value)}
              checked={surveyResult === opt}
            />
          ))}
        </div>
        <Button
          fullWidth
          disabled={!surveyResult}
          loading={surveySubmitting}
          onClick={() => {
            if (!surveyResult) return
            setSurveySubmitting(true)
            onComplete(surveyResult)
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  )
}

interface RadioButtonProps {
  checked: boolean
  option: string
  onChange: (value: string) => void
}
const RadioButton = ({ checked, option, onChange }: RadioButtonProps) => (
  <label className='flex items-center gap-x-2.5 cursor-pointer'>
    <input
      type='radio'
      checked={checked}
      onChange={(value) => onChange(value.target.value)}
      value={option}
    />
    {option}
  </label>
)
