import {
  BillingFeatures,
  BillingPaymentPage,
  SubmitButton,
} from '@motion/web-billing'
import {
  getSegmentAnalytics,
  getTrackingCookies,
} from '@motion/web-common/analytics'
import { isMobileExperience } from '@motion/web-common/env'
import { Sentry } from '@motion/web-common/sentry'
import { useRefreshSubscription } from '@motion/web-common/subscriptions'

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
  type StripeErrorType,
  type StripePaymentElementChangeEvent,
} from '@stripe/stripe-js'
import { LoadingPage } from '~/areas/global'
import logo from '~/images/logo_1024.png'
import logoName from '~/images/motion_logo.svg'
import MD5 from 'crypto-js/md5'
import { useEffect, useState } from 'react'

import { CheckoutSurvey } from './checkout-survey'

import { logEvent } from '../../analytics'
import { useTrialLength } from '../../experiments'
import { getCloudRunUrl, useOnPurchaseRedirect } from '../../utils'
import { valueMap } from '../constants'
import { useGetTeamPricesUnauthorized } from '../hooks'

const isMobile = isMobileExperience()

interface PaymentFormNewProps {
  userEmail: string
  signOutEmail: () => void
  isTrial: boolean
  outerError: string | null
  updateClientSecret: (secret: string, error: string) => void
  isTeam: boolean
  onBack?: () => void
}

export const PaymentForm = ({
  userEmail,
  signOutEmail,
  isTrial,
  outerError,
  updateClientSecret,
  isTeam,
  onBack,
}: PaymentFormNewProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isPaymentFilledOut, setIsPaymentFilledOut] = useState(false)
  const [error, setError] = useState<string | null>(outerError)
  const [showSurvey, setShowSurvey] = useState(false)
  const [isAnnual, setIsAnnual] = useState<boolean>(true)
  const [hasSelectedCard, setHasSelectedCard] = useState(true)
  const stripe = useStripe()
  const elements = useElements()
  const [bucketSeats, setBucketSeats] = useState(5)

  const trialFFLength = useTrialLength()
  const trialLength = isTrial ? trialFFLength : undefined

  const [refreshingSubscription, setRefreshingSubscription] = useState(false)
  const refreshSubscription = useRefreshSubscription()

  const { data: teamPrices } = useGetTeamPricesUnauthorized({
    email: userEmail,
  })

  const [redirectStatus, setRedirectStatus] = useState<
    'not_ready' | 'ready' | 'redirecting'
  >('not_ready')
  const onPurchaseRedirect = useOnPurchaseRedirect()

  useEffect(function logAnalytics() {
    getSegmentAnalytics()?.track('add_to_cart', {
      category: 'checkout',
      url: window.location.href,
    })
  }, [])

  useEffect(() => {
    if (redirectStatus === 'ready' && !refreshingSubscription) {
      setRedirectStatus('redirecting')
      onPurchaseRedirect()
    }
  }, [redirectStatus, refreshingSubscription, onPurchaseRedirect])

  const logPurchaseEvent = (subscriptionId: string) => {
    try {
      const cookieData = getTrackingCookies()
      const data = {
        category: 'checkout',
        label: isAnnual ? 'motion_annual' : 'motion_monthly',
        value: isAnnual ? valueMap.motion_annual : valueMap.motion_monthly,
        trialLength,
        currency: 'usd',
        action_source: 'website',
        url: window.location.href,
        customer_id: MD5(userEmail!).toString(),
        subscription_id: subscriptionId,
        messageId: `${subscriptionId}-trialStart`,
        isTeam: isTeam,
        seats: isTeam ? bucketSeats : undefined,
        ...cookieData,
      }
      // doesn't have name because it's a email-only signup flow
      const traitData = {
        traits: {
          email: userEmail,
        },
      }
      if (trialLength) {
        // maps to Segment StartTrial event
        getSegmentAnalytics()?.track('success_purchase', data, traitData)
        // maps to Segment Purchase event
        getSegmentAnalytics()?.track('success_purchase_new', data, traitData)
        if (!hasSelectedCard) {
          logEvent('TRIAL_START_WALLET')
        }
      } else {
        getSegmentAnalytics()?.track(
          'success_purchase_resubscribe',
          data,
          traitData
        )
      }
    } catch (e) {
      Sentry.captureException(e, { tags: { position: 'logPurchaseEvent' } })
    }
  }

  const handlePaymentElementChange = (
    event: StripePaymentElementChangeEvent
  ) => {
    setHasSelectedCard(event.value.type === 'card')
    setIsPaymentFilledOut(event.complete)
  }

  const completeHandler = (
    newPurchase: boolean,
    shouldLog: boolean,
    subscriptionId: string
  ) => {
    if (shouldLog) {
      logPurchaseEvent(subscriptionId)
    }
    if (newPurchase) {
      setShowSurvey(true)
    } else {
      setRedirectStatus('ready')
    }
  }

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return
    }
    setIsSubmitting(true)

    try {
      const payload = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      })

      if (payload.error) {
        setIsSubmitting(false)
        setError(`Error: ${payload.error.message}`)

        logEvent('ERROR_STRIPE_PAYMENT_PAYLOAD', {
          error: payload.error,
        })
        const filterOutErrorTypes: StripeErrorType[] = [
          'validation_error',
          'rate_limit_error',
          'card_error',
        ]
        // Filter out these errors, as they are not actionable
        if (
          !filterOutErrorTypes.some((type) => payload.error.type.includes(type))
        ) {
          Sentry.captureException(
            new Error('Payload error from stripe confirmPayment.', {
              cause: payload.error,
            }),
            {
              tags: { position: 'confirmPayment' },
              extra: { payload },
            }
          )
        }
      } else {
        setError(null)
        await createSubscription(
          payload.paymentIntent!.payment_method! as string
        )
      }
    } catch (e) {
      Sentry.captureException(e, { tags: { position: 'handleSubmit' } })
    }
  }

  const createSubscription = (paymentMethodId: string) => {
    const cookieData = getTrackingCookies()
    return fetch(getCloudRunUrl('users/deprecated/subscription/unauthorized'), {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        paymentMethodId,
        label: isAnnual ? 'motion_annual' : 'motion_monthly',
        email: userEmail,
        isMonthly: !isAnnual,
        trialLength,
        ps_xid: cookieData.ps_xid,
        seats: isTeam ? bucketSeats : undefined,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.newClientSecret) {
          updateClientSecret(result.newClientSecret, result.message)
        }
        if (result.error) {
          throw result.error
        }
        return result
      })
      .then((result) => {
        return { paymentMethodId, subscription: result.subscription }
      })
      .then(onSubscriptionComplete)
      .catch((error) => {
        if (error.message === 'You already purchased this item!') {
          completeHandler(false, false, '')
        } else {
          setError(error.message)
          getSegmentAnalytics()?.track('PAYMENT_ERROR', { error })
          Sentry.captureException(error, {
            tags: { position: 'createSubscription' },
          })
        }
      })
      .finally(() => setTimeout(() => setIsSubmitting(false), 1500))
  }

  const onSubscriptionComplete = ({ subscription }: { subscription: any }) => {
    if (
      subscription &&
      (subscription.status === 'active' || subscription.status === 'trialing')
    ) {
      setRefreshingSubscription(true)
      void refreshSubscription
        .mutateAsync()
        .finally(() => setRefreshingSubscription(false))

      completeHandler(isTrial, true, subscription.id)
    }
  }

  if (redirectStatus !== 'not_ready' || !teamPrices) {
    return <LoadingPage id='refresh-sub-and-redirect' />
  }

  const teamProps = isTeam
    ? { isTeam, setBucket: setBucketSeats, bucket: bucketSeats, teamPrices }
    : { isTeam }

  return (
    <div className='bg-wlight-500 flex h-screen w-screen flex-col items-center gap-y-6 overflow-y-scroll py-6 px-3'>
      <div
        className={`w-full max-w-screen-xl flex flex-row items-center justify-between ${
          showSurvey ? 'blur-sm' : 'blur-none'
        }`}
      >
        <div className='flex gap-x-3'>
          <img src={logo} className='h-10 w-10' alt='Motion logo' />
          <img className='fill-wdark-100' src={logoName} alt='Motion name' />
        </div>
        <a
          href='https://help.usemotion.com'
          target='_blank'
          rel='noreferrer'
          className='text-wlight-1000 m-0 p-0 text-sm font-semibold'
        >
          Contact Support
        </a>
      </div>
      <div
        className={`flex w-full max-w-screen-xl flex-col lg:flex-row lg:items-center ${
          showSurvey ? 'blur-sm' : 'blur-none'
        }`}
      >
        <BillingPaymentPage
          paymentElement={
            <PaymentElement onChange={handlePaymentElementChange} />
          }
          trialLength={trialLength}
          isAnnual={isAnnual}
          setIsAnnual={setIsAnnual}
          isMobile={isMobile}
          userEmail={userEmail}
          onChangeEmail={signOutEmail}
          hasSelectedCard={hasSelectedCard}
          error={error}
          isPaymentFilledOut={isPaymentFilledOut}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          onBack={onBack}
          {...teamProps}
        />
        <BillingFeatures trialLength={trialLength} />
      </div>
      {isMobile && (
        <div className='mt-2 self-center'>
          <SubmitButton
            disabled={!isPaymentFilledOut}
            loading={isSubmitting}
            onClick={handleSubmit}
            trialLength={trialLength}
          />
        </div>
      )}
      {showSurvey && (
        <CheckoutSurvey
          onComplete={(result) => {
            getSegmentAnalytics()?.track('checkout_survey', {
              value: result,
              ...getTrackingCookies(),
            })
            // delayed so logging can process
            setTimeout(() => setRedirectStatus('ready'), 1500)
          }}
        />
      )}
    </div>
  )
}
