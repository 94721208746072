import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'

import logo from '~/images/logo_1024.png'
import oauthClientSelection from '~/images/oauth_client_selection.png'
import oauthSearchImage from '~/images/oauth_search.png'
import { useState } from 'react'

const WEB_CLIENT_ID =
  '808943271132-v6g3n8rr5drt26veifh2ubca8453tqc7.apps.googleusercontent.com'
const IOS_CLIENT_ID =
  '808943271132-bbgsh4jl0tvu5ua54tscr9prb572f07b.apps.googleusercontent.com'
const ANDROID_CLIENT_ID =
  '808943271132-48bh2jrmqag4iiun74um714h4g5b85g6.apps.googleusercontent.com'

export function OauthInstructions() {
  const [admin, setAdmin] = useState<boolean | null>(null)

  const renderNonAdminInstructions = () => {
    return (
      <>
        <OAuthBullet>
          Try adding Motion with a limited set of permissions. Some features
          will be limited. If it still doesn't work, share{' '}
          <a
            href='https://app.usemotion.com/oauth-instructions'
            target='_blank'
            className='underline'
            rel='noopener noreferrer'
          >
            this page
          </a>{' '}
          with your organization's admin, or contact support at
          https://help.usemotion.com.
        </OAuthBullet>
        <div className='self-center'>
          <Button
            url={`${window.location.protocol}//${window.location.host}/add-limited-account`}
          >
            Try Motion with limited permissions
          </Button>
        </div>
      </>
    )
  }

  const renderAdminInstructions = () => {
    return (
      <>
        <div className='text-semantic-neutral-text-default text-lg'>
          To connect your Google account to Motion, you'll need to mark Motion
          as a trusted third-party application (or share{' '}
          <a
            className='underline'
            href='https://app.usemotion.com/oauth-instructions'
            target='_blank'
            rel='noopener noreferrer'
          >
            this page
          </a>{' '}
          with your organization's admin so they can do so). Please follow the
          steps below to grant yourself these permissions. If the issue
          persists, please contact support at https://help.usemotion.com
        </div>
        <OAuthBullet>
          <div>
            1. Go to{' '}
            <a
              className='underline'
              href='https://admin.google.com/ac/owl/list?tab=apps'
              target='_blank'
              rel='noopener noreferrer'
            >
              https://admin.google.com/ac/owl/list?tab=apps
            </a>
          </div>
        </OAuthBullet>
        <OAuthBullet>
          <div>2. Click on "View List" in the Configured apps section.</div>
          <OAuthImage
            src='https://uploads-ssl.webflow.com/5fcb349058268443b1f5dbdb/6244e069c81ed437656b7b22_Screen%20Shot%202022-03-30%20at%203.52.14%20PM.png'
            alt='oauth_instructions_2'
          />
        </OAuthBullet>
        <OAuthBullet>
          <div>3. Click on "OAuth App Name Or Client ID"</div>
          <OAuthImage
            src='https://uploads-ssl.webflow.com/5fcb349058268443b1f5dbdb/6244e0695d6a21a630e4875f_Screen%20Shot%202022-03-30%20at%203.54.09%20PM.png'
            alt='oauth_instructions_3'
          />
        </OAuthBullet>
        <OAuthBullet>
          <div>
            4. Search for the following client id to find Motion's web
            application: <b>{WEB_CLIENT_ID}</b>
          </div>
          <OAuthImage src={oauthSearchImage} alt='oauth_instructions_4' />
        </OAuthBullet>
        <OAuthBullet>
          <div>5. Check all the boxes, and click "Select"</div>
          <OAuthImage src={oauthClientSelection} alt='oauth_instructions_5' />
        </OAuthBullet>
        <OAuthBullet>
          <div>
            6. Check "Trusted: Can access all Google services" and click
            "Configure"
          </div>
          <OAuthImage
            src='https://uploads-ssl.webflow.com/5fcb349058268443b1f5dbdb/607f5bdbfef37b1bf2edd8a5_oauth_instructions_5.png'
            alt='oauth_instructions_6'
          />
        </OAuthBullet>
        <OAuthBullet>
          <div>
            7. Repeat steps 3-6, but search instead for the client ID of
            Motion's iOS app: <b>{IOS_CLIENT_ID}</b>
          </div>
        </OAuthBullet>
        <OAuthBullet>
          <div>
            8. Repeat steps 3-6, but search instead for the client ID of
            Motion's Android app: <b>{ANDROID_CLIENT_ID}</b>
          </div>
        </OAuthBullet>
      </>
    )
  }

  return (
    <div className='bg-semantic-neutral-bg-active-default grid h-lvh grid-rows-[auto,1fr]'>
      <div className='bg-semantic-neutral-bg-default p-3'>
        <img src={logo} width='40px' height='40px' alt='Motion logo' />
      </div>

      <div className='overflow-y-scroll p-6 h-full flex flex-col items-center'>
        <div className='max-w-[600px] flex flex-col'>
          <div className='font-bold text-xl text-semantic-neutral-text-default text-center mb-3'>
            Fix Google Authentication Error
          </div>
          <div className='text-lg text-semantic-neutral-text-default mb-8'>
            It seems like you don't have all the permissions required to use
            Motion. Here are instructions on how to fix the issue:
          </div>
          {admin === null ? (
            <div className='flex flex-col self-center gap-[10px] w-fit'>
              <Button fullWidth onClick={() => setAdmin(true)}>
                I'm admin of my organization
              </Button>
              <Button
                fullWidth
                sentiment='neutral'
                onClick={() => setAdmin(false)}
              >
                I am not an admin of my org
              </Button>
            </div>
          ) : (
            <>
              {admin ? renderAdminInstructions() : renderNonAdminInstructions()}
              <div>
                <Button sentiment='neutral' onClick={() => setAdmin(null)}>
                  Back
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const OAuthBullet = classed('div', {
  base: 'text-semantic-neutral-text-default text-base font-medium mb-8',
})

const OAuthImage = classed('img', {
  base: 'w-full rounded mt-[10px]',
})
