import { useAuth } from '@motion/web-common/auth'
import {
  newOnboardingPostPurchasePages,
  useRequiresOnboarding,
} from '@motion/web-common/onboarding'
import {
  EnsureFirestoreSettingsFetched,
  useSetting,
} from '@motion/web-common/settings'

import { useEffect } from 'react'

import { EnsureAuthenticated } from './areas/auth/components/ensure-authenticated'
import { CheckoutPaymentPage } from './checkout/_components/checkout-payment-page'

export const AddPaymentLocalized = () => {
  const { auth, signOut } = useAuth()

  return (
    <EnsureAuthenticated>
      {auth.state === 'authenticated' && (
        <EnsureFirestoreSettingsFetched>
          <RedirectUnlessResubscriber>
            <CheckoutPaymentPage
              userEmail={auth.user.email}
              signOutEmail={signOut}
              isTrial={false}
            />
          </RedirectUnlessResubscriber>
        </EnsureFirestoreSettingsFetched>
      )}
    </EnsureAuthenticated>
  )
}

const RedirectUnlessResubscriber = ({
  children,
}: {
  children: React.ReactNode
}) => {
  // This page is specifically for users who have subscribed in the past.
  // If they somehow got here before ever subscribing then send them to onboarding.
  // This check will be easier to make in the future when we retain subscription
  // data even after a user's subscription has ended.
  const requiresOnboarding = useRequiresOnboarding()
  const [onboardingLatestScreen] = useSetting('onboardingLatestScreen', '')
  const shouldRedirect =
    requiresOnboarding &&
    !newOnboardingPostPurchasePages.includes(onboardingLatestScreen)
  useEffect(() => {
    if (shouldRedirect) {
      window.location.href = '/web'
    }
  }, [shouldRedirect])

  if (shouldRedirect) {
    return null
  }

  return children
}
