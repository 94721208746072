import { CheckOutline } from '@motion/icons'
import { Button, LoadingSpinner } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { errorInDev } from '@motion/web-common/logging'
import { Sentry } from '@motion/web-common/sentry'

import { LoadingPage } from '~/areas/global'
import logo from '~/images/logo_1024.png'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  formatFullDate,
  formatFullDateTimeRange,
  formatTimeRange,
} from './utils'

import { logEvent } from '../analytics'
import { getBookingUrl, getCloudRunUrl, getTzAbbr } from '../utils'

const getBookingInfoUrl = (bookingId: string) => {
  return `${getCloudRunUrl('booking/inviteeBooking')}/${bookingId}`
}
const getCancelUrl = (bookingId: string) =>
  getBookingUrl('booking/cockroachCancel', bookingId)

type cardPageType = 'booking' | 'confirmation'

export const BookingCancelPage = () => {
  const [loading, setLoading] = useState(true)
  const [loadError, setLoadError] = useState(false)
  const [savingError, setSavingError] = useState('')
  const [saving, setSaving] = useState(false)
  const [hostDisplayName, setHostDisplayName] = useState('')
  const [externalEventName, setExternalEventName] = useState('')
  const [cardPage, setCardPage] = useState<cardPageType>('booking')
  const [tzAbbr, setTzAbbr] = useState('')
  const [eventStart, setEventStart] = useState('')
  const [eventEnd, setEventEnd] = useState('')
  const [reason, setReason] = useState('')
  const { bookingId } = useParams<{ bookingId: string }>()

  useEffect(() => {
    const getAvailabilitiesDetails = async () => {
      if (!bookingId) {
        setLoadError(true)
        setLoading(false)
        return
      }
      try {
        const response = await fetch(`${getBookingInfoUrl(bookingId)}`)
        const result = await response.json()

        if (result.error) {
          throw result.error
        } else {
          if (!result.bookingLink) {
            throw new Error('Failed to pull booking link')
          }
          setLoadError(false)
          setExternalEventName(result.bookingLink.externalEventName || '')
          setHostDisplayName(result.bookingLink.hostDisplayName || '')
          setCardPage(result.isCanceled ? 'confirmation' : 'booking')

          if (!result.eventStart || !result.eventEnd) {
            throw new Error('Missing event start and end')
          }
          setEventStart(result.eventStart)
          setEventEnd(result.eventEnd)

          setTzAbbr(getTzAbbr(DateTime.now().zoneName))
        }
      } catch (e) {
        errorInDev(e)
        setLoadError(true)
        Sentry.captureException(e, {
          tags: { position: 'getAvailabilitiesDetails' },
        })
      } finally {
        setLoading(false)
      }
    }

    void getAvailabilitiesDetails()
  }, [bookingId])

  useEffect(() => {
    logEvent('AVAILABILITY_CANCEL_LINK')
  }, [])

  const confirmCancel = async () => {
    setSaving(true)
    try {
      if (!bookingId) {
        throw new Error('No booking id provided')
      }
      const result = await fetch(getCancelUrl(bookingId), {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          reason,
          bookingId,
        }),
      })
      if (result && result.ok) {
        setCardPage('confirmation')
      } else {
        const jsonResult = await result.json()
        throw new Error(jsonResult?.error?.message || jsonResult?.message)
      }
      logEvent('AVAILABILITY_CANCELED')
    } catch (e: any) {
      setSavingError(e.message || 'Issue while trying to cancel this meeting')
      logEvent('AVAILABILITY_BOOKING_ERROR', { message: e.message })
      Sentry.captureException(e, { tags: { position: 'confirmCancel' } })
    } finally {
      setSaving(false)
    }
  }

  const signature = (
    <a
      className='availabilities-signature'
      href='https://www.usemotion.com/referral?ref=booking'
      target='_blank'
      onClick={() => logEvent('AVAILABILITY_SIGNATURE_CLICK')}
      rel='noopener noreferrer'
    >
      <img src={logo} className='availabilities-logo' alt='Motion logo' />
      Powered by Motion
    </a>
  )

  const renderBanner = () => {
    return (
      <div className='availabilities-banner'>
        <div className='availabilities-banner-stripe' />
        <div className='availabilities-banner-content'>
          {cardPage === 'confirmation' ? (
            <div className='availability-confirmation-banner-title'>
              Meeting canceled successfully
            </div>
          ) : (
            <div className='availability-title'>Cancel scheduled meeting.</div>
          )}
        </div>
      </div>
    )
  }

  const renderBookingCard = () => {
    return cardPage === 'booking' ? (
      <div className='availabilities-booking-inner'>
        <div className='availabilities-date-column'>
          {externalEventName || hostDisplayName ? (
            <h4
              style={{
                marginBottom: '0.5em',
                color: 'rgba(0, 0, 0, 0.85)',
                fontWeight: 600,
                fontSize: 20,
                lineHeight: 1.4,
              }}
            >
              {externalEventName || `Meeting with ${hostDisplayName}`}
            </h4>
          ) : null}
          <div className='availabilities-cancel-subtitle'>
            {formatFullDate(eventStart)}
          </div>
          <div className='availabilities-cancel-subtitle'>
            {formatTimeRange(eventStart, eventEnd, tzAbbr)}
          </div>
        </div>
        <div className='availabilities-cancel-right-container'>
          {loading ? (
            <div className='availabilities-spinner-container'>
              <LoadingSpinner color='#979797' />
            </div>
          ) : (
            <div className='availabilities-cancel-container'>
              <div
                className='availabilities-datetimes-header'
                style={{ whiteSpace: 'nowrap' }}
              >
                Cancel Event
              </div>
              <div
                className='availabilities-input-label'
                style={{ marginTop: 16 }}
              >
                Reason for canceling
              </div>
              <TextField
                value={reason}
                onChange={(value) => setReason(value)}
              />
              <Button
                onClick={() => {
                  if (!saving) {
                    void confirmCancel()
                  }
                }}
                // @ts-expect-error - old styling
                className='availabilities-cancel-button'
                disabled={saving}
              >
                Cancel Event
                {saving ? (
                  <LoadingSpinner className='ml-3' size={22} color='#979797' />
                ) : null}
              </Button>
              {savingError ? (
                <div className='availabilities-error'>{savingError}</div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    ) : null
  }

  const renderConfirmation = () => {
    return cardPage === 'confirmation' ? (
      <div className='availabilities-confirm-inner-container'>
        <div className='availabilities-confirm-check-container'>
          <CheckOutline
            className='availabilities-confirm-check'
            style={{
              backgroundColor: '#2C77E7',
              color: 'white',
              borderRadius: '50%',
              width: 'auto',
              padding: '8px',
            }}
          />
        </div>
        <div className='availabilities-confirm-title'>Meeting canceled!</div>
        <div className='availabilities-confirm-subtitle'>
          {formatFullDateTimeRange(eventStart, eventEnd, tzAbbr)}
        </div>
      </div>
    ) : null
  }

  const renderCard = () => {
    return (
      <div className='availabilities-card-container'>
        <div
          className={`availabilities-cancel-card${
            cardPage === 'confirmation'
              ? ' availabilities-card-confirmation'
              : ''
          }`}
        >
          {renderBookingCard()}
          {renderConfirmation()}
        </div>
        {signature}
      </div>
    )
  }

  if (loadError) {
    return (
      <div className='availabilities-loading-container fill-page'>
        <div className='availabilities-error-card'>
          <div style={{ color: '#151515' }}>
            Issue loading this booking page. The page may have expired, or the
            URL may have been entered incorrectly.
          </div>
        </div>
      </div>
    )
  }

  if (loading) {
    return <LoadingPage id='booking-cancel-page' />
  }

  return (
    <div className='availabilities-container fill-page' data-theme='light'>
      {renderBanner()}
      {renderCard()}
    </div>
  )
}
