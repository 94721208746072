import { LoadingSpinner } from '@motion/ui/base'
import { errorInDev } from '@motion/web-common/logging'
import { Sentry } from '@motion/web-common/sentry'

import logo from '~/images/logo_1024.png'
import popupInstruction from '~/images/popup_instruction.png'
import { getApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, type User } from 'firebase/auth'
import { useEffect, useState } from 'react'

import { getEnv } from './env'
import { getCloudRunUrl } from './utils'

enum ProcessState {
  UNPROCESSED = 'UNPROCESSED',
  AWAITING_DESKTOP = 'AWAITING_DESKTOP',
  PROCESSED = 'PROCESSED',
}

export const ZoomOauth = () => {
  const [processed, setProcessed] = useState<ProcessState>(
    ProcessState.UNPROCESSED
  )
  const [error, setError] = useState('')
  const [user, setUser] = useState<User | null>(null)
  const auth = getAuth(getApp())

  useEffect(
    function firebaseAuthListener() {
      onAuthStateChanged(auth, (user) => {
        setUser(user ?? null)
      })
    },
    [auth, auth.currentUser]
  )

  useEffect(
    function processURLAndGetToken() {
      const run = async () => {
        const newParams = new URLSearchParams(window.location.search)
        const authorizationCode = newParams.get('code')
        const state = newParams.get('state')
        const errorCode = newParams.get('error')

        try {
          if (errorCode === 'access_denied') {
            setError(
              "To connect your Zoom account to Motion, you'll need to have your organization admin grant " +
                'permissions for third-party applications to access your account. They can visit their Admin Center and ' +
                'update the Integrated Apps settings. If the issue persists, please contact support at https://help.usemotion.com'
            )
            return
          }
          if (!authorizationCode) {
            throw new Error('no authorization code')
          }

          const env = getEnv()
          let redirectUrl
          switch (env) {
            case 'localhost':
              redirectUrl = 'https://inmotion.app/zoom-oauth-dev'
              break
            default:
              redirectUrl = `${window.location.protocol}//${window.location.host}/zoom-oauth`
              break
          }

          const response = await fetch(getCloudRunUrl('zoom/tokens'), {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
            },
            body: JSON.stringify({
              code: authorizationCode,
              hostUrl: redirectUrl,
            }),
          })

          if (!response.ok) {
            throw new Error('Error initializing Zoom tokens')
          }

          if (state) {
            const stateParams = JSON.parse(state)

            if (stateParams.desktopApp) {
              window.open(
                `motion-desktop://zoom-oauth?code=${authorizationCode}`
              )
              setProcessed(ProcessState.AWAITING_DESKTOP)
              return
            }
          }

          const message = {
            event: 'processZoomAuthObject',
            worker: true,
          }
          if (window.opener) {
            window.opener.postMessage(message)
          } else {
            window.localStorage.setItem(
              'motionMessage',
              JSON.stringify(message)
            )
          }

          window.close()
          setProcessed(ProcessState.PROCESSED)
        } catch (e) {
          errorInDev(e)
          setError(
            'Error connecting your Zoom account. Please contact support at https://help.usemotion.com'
          )
          Sentry.captureException(e, {
            tags: { position: 'processURLAndGetToken' },
          })
        }
      }

      if (user) {
        run()
      }
    },
    [user, auth.currentUser]
  )

  return (
    <div className='fill ms-oauth-container'>
      <img src={logo} className='ob-logo' alt='Motion logo' />
      {error && processed === ProcessState.UNPROCESSED && (
        <p style={{ fontSize: 18, maxWidth: '70vw' }}>{error}</p>
      )}
      {processed === ProcessState.PROCESSED && (
        <>
          <h1
            style={{
              fontWeight: 900,
              marginBottom: '0.5em',
              fontSize: 38,
              lineHeight: 1.23,
            }}
          >
            Success!
          </h1>
          <p style={{ fontSize: 18, marginBottom: '1em' }}>
            You've connected your Zoom account to Motion. You may close this
            window.
          </p>
        </>
      )}
      {processed === ProcessState.AWAITING_DESKTOP && (
        <>
          <img
            src={popupInstruction}
            className='popup-instructions-image '
            alt='Popup instruction'
          />
          <h1
            style={{
              fontWeight: 900,
              marginBottom: '0.5em',
              fontSize: 38,
              lineHeight: 1.23,
            }}
          >
            Connecting your zoom account to your desktop client
          </h1>
          <p style={{ fontSize: 18, marginBottom: '1em' }}>
            Please ensure you select 'allow' on the popup that appears in your
            browser and try connecting your Zoom account again in order to
            complete your authorization.
          </p>
          <p style={{ fontSize: 16, marginBottom: '1em' }}>
            You can close this window once the desktop app has been authorized.
          </p>
        </>
      )}
      {processed === ProcessState.UNPROCESSED && !error && (
        <LoadingSpinner color='#979797' />
      )}
    </div>
  )
}
