import { featureFlags } from '@motion/web-common/flags'

import { LoadingPage } from '~/areas/global'
import { useEffect, useState } from 'react'

export const AmplitudeExperimentProvider = (props: {
  userEmail: string | null
  children: React.ReactNode
}) => {
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    // If the emails already match, then just continue
    if (featureFlags.email === props.userEmail) {
      setInitialized(true)
      return
    }
    void featureFlags.identify(props.userEmail).then(() => setInitialized(true))
  }, [props.userEmail])

  if (!initialized) {
    return <LoadingPage id='amplitude' />
  }

  return props.children
}
