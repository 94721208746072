import { classed } from '@motion/theme'
import { ANDROID_APP_LINK, IOS_APP_LINK } from '@motion/ui-logic'

import logo from '~/images/logo_1024.png'
import { getApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import { Link } from 'react-router-dom'

export const MobileInstall = () => {
  const [emailParam, setEmailParam] = useState<string>('')
  const auth = getAuth(getApp())

  const isDesktopApp =
    navigator?.userAgent?.toLowerCase()?.indexOf(' electron/') > -1

  const isMac = window.navigator.userAgent.includes('Mac')

  useEffect(
    function handleAuth() {
      onAuthStateChanged(auth, (user) => {
        if (user?.email) {
          setEmailParam(window.btoa(user.email))
        } else {
          setEmailParam('')
        }
      })
    },
    [auth]
  )

  return (
    <div className='fill flex flex-col items-center'>
      <div className='flex w-full items-center justify-between border-b-2 px-6 py-4 electron-drag'>
        <LogoContainer isDesktopMacApp={isDesktopApp && isMac}>
          <Link to='/'>
            <img src={logo} className='h-12 w-12' alt='Motion logo' />
          </Link>
        </LogoContainer>

        {isDesktopApp ? (
          <div className='electron-no-drag'>
            <Link to='/web/calendar'>
              <p className='text-base font-medium'>Back to App</p>
            </Link>
          </div>
        ) : (
          <Link to='/account'>
            <p className='text-base font-medium'>Account</p>
          </Link>
        )}
      </div>
      <p className='m-5 text-5xl font-medium text-black'>Motion Mobile App</p>
      <p className='text-gray mb-5 text-2xl font-semibold'>
        Scan the QR code or click the links below to get Motion for your phone
      </p>
      <div className='mb-8'>
        <QRCode
          value={`https://www.usemotion.com/mobile${
            emailParam ? '?e=' + emailParam : ''
          }`}
        />
      </div>
      <a href={IOS_APP_LINK} target='_blank' rel='noreferrer'>
        <img
          alt='App Store'
          width='200'
          src='https://assets.website-files.com/5fcb349058268443b1f5dbdb/611d378b208bbb49ae4d5cca_black.svg'
        />
      </a>
      <a href={ANDROID_APP_LINK} target='_blank' rel='noreferrer'>
        <img
          alt='Google Play'
          width='230'
          src='https://assets.website-files.com/5fcb349058268443b1f5dbdb/6137b33f586e1443731d8568_google-play.png'
        />
      </a>
    </div>
  )
}

const LogoContainer = classed('div', {
  base: 'electron-no-drag',
  variants: {
    // Workaround for stoplights placement being in navbar (mac app only)
    isDesktopMacApp: { true: 'ml-14' },
  },
})
