import { useShouldShowLoginEmailNotFound } from '~/areas/auth/hooks'
import { LoadingPage } from '~/areas/global'
import { getApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { LoginEmailNotFoundModal } from './areas/auth/modals/login-email-not-found-modal'
import { LoginPage } from './areas/auth/pages/login-page'

export function NewTabPage() {
  const [loading, setLoading] = useState(true)
  const auth = getAuth(getApp())
  const navigate = useNavigate()

  const shouldShowLoginEmailNotFound = useShouldShowLoginEmailNotFound()
  const [loginNoEmailFound, setLoginNoEmailFound] = useState<string | null>(
    null
  )

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // If user signed up for the first time in the last minute, we need to ask if they intended to sign up.
        if (await shouldShowLoginEmailNotFound(user)) {
          setLoginNoEmailFound(user.email)
          return
        }

        // Once user is loaded, show loader (to hide the 'no sub' screen), to
        // allow us to pull stripe sub info.
        setLoading(true)
        navigate('/web')
      } else {
        setLoading(false)
      }
    })
  }, [auth, navigate, shouldShowLoginEmailNotFound])

  return (
    <div
      className='fill'
      style={{
        overflow: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {loading ? (
        <LoadingPage id='webapp-new-tab-page' />
      ) : (
        <>
          <LoginPage showIframe />
          {!!loginNoEmailFound && (
            <LoginEmailNotFoundModal
              email={loginNoEmailFound}
              onSignUp={() => {
                setLoading(true)
                navigate('/web')
              }}
              onDismiss={() => {
                setLoginNoEmailFound(null)
                auth.signOut()
              }}
            />
          )}
        </>
      )}
    </div>
  )
}
